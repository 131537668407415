import React from 'react';
import {Guess} from './board'
import Wordle from './wordle'
import WordleGuessor from './wordle-guessor'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import {green, yellow, grey} from '@mui/material/colors'
import './board.css'

function ResultChooser(props) {
    console.log(props)
    let inputElements = [];
    console.log(props);
    for(let i=0; i <5; i++) {
        inputElements.push(
        <FormControl>
        <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={props.values[i]}
            onChange={event => props.notify(i, event.target.value)}
        >
            <Radio value="green" sx={{color: green[800], '&.Mui-checked': {
            color: green[800]}}}/>
            <Radio value="yellow" sx={{color:yellow[500], '&.Mui-checked': {
            color: yellow[500],
          }}}/>
            <Radio value="gray" sx={{color:grey[500],
            '&.Mui-checked': {
            color: grey[500],
          }}}/>
        </RadioGroup>
        </FormControl>
        );
    }
    return (
        <div>
            {inputElements}
        </div>
        );
}
class GuessBoard extends React.Component {
    constructor(props) {
        super(props);
        let backgrounds = []
        this.guessRefs = [];
        this.selectRefs = [];
        for (let i=0; i<5; i++) {
            this.selectRefs.push(React.createRef());
        }
        for (let i = 0; i < 6; i++) {
            this.guessRefs.push([]);
            backgrounds.push([]);
            for (let j = 0; j < 5; j++) {
                this.guessRefs[i].push(React.createRef());
                backgrounds[i].push("white");
            }
        }
        let game = new Wordle();
        let guessor = new WordleGuessor(game);
        let guesses = Array.from(Array(6), () => new Array(5).fill(""));
        const newGuess = guessor.generateGuess();
        guesses[0] = newGuess.split("");
        this.state = {
            guesses: guesses,
            chooserValues: new Array(5).fill("gray"),
            backgrounds: backgrounds,
            numGuesses: 0,
            helpText: '',
            game: game,
            guessor: guessor
        };
    }

    onEnter() {
        let word = this.state.guesses[this.state.numGuesses].join("");
        word = word.toLowerCase();
        if (!this.state.guessor.game.isWordValid(word)) {
            this.setState({helpText:"Invalid word!"});
            return;
        }
        let reportedResult = '';
        let tempBackgrounds = this.state.backgrounds;
        this.selectRefs.forEach((ref, i) => {
            tempBackgrounds[this.state.numGuesses][i] = this.state.chooserValues[i];
            console.log(ref);
            if (this.state.chooserValues[i] === "green") {
                reportedResult = reportedResult.concat('=');
            } else if (this.state.chooserValues[i] === "yellow") {
                reportedResult = reportedResult.concat('+');
            } else if (this.state.chooserValues[i] === "gray") {
                reportedResult = reportedResult.concat('-');
            } else {
                this.setState({helpText:"Please select results"});
                return;
            }
        });
        this.setState({background:tempBackgrounds});
        this.state.guessor.updateGameState(word, reportedResult);
        const newGuess = this.state.guessor.generateGuess();
        let newGuesses = [...this.state.guesses];
        newGuesses[this.state.numGuesses+1] = newGuess.split("");
        this.setState({numGuesses:this.state.numGuesses+1, guesses: newGuesses});
    }

    onChange(event, i) {
        let newGuesses = [...this.state.guesses];
        newGuesses[this.state.numGuesses] = [...newGuesses[this.state.numGuesses]];
        newGuesses[this.state.numGuesses][i] = event.target.value;
        this.setState({guesses: newGuesses});
    }

    onRestart() {
        let backgrounds = [];
        for (let i = 0; i < 6; i++) {
            backgrounds.push([]);
            for (let j = 0; j < 5; j++) {
                backgrounds[i].push("white");
            }
        }
        let newGame = new Wordle();
        let newGuesses = Array.from(Array(6), () => new Array(5).fill(""))
        const newGuess = this.state.guessor.generateGuess();
        newGuesses[0] = newGuess.split("");
        this.setState({
            guesses:  newGuesses,
            chooserValues: new Array(5).fill("gray"),
            backgrounds: backgrounds,
            numGuesses: 0,
            helpText:'',
            game: newGame,
            guessor: new WordleGuessor(newGame)
        });
        
        this.setState({guesses: newGuesses});
    }


    renderGuess(i) {
        return (
            <Guess inputRefs={this.guessRefs[i]}
            value={this.state.guesses[i]}
            key={"guess " + i}
            isEditable={i===this.state.numGuesses}
            onEnter={()=>{
                if (i === this.state.numGuesses) {
                    this.onEnter();
                }
            }}
            onChange={(event, j)=>{
                if (i === this.state.numGuesses) {
                    this.onChange(event, j);
                }}
            }
            background={this.state.backgrounds[i]}
            />
        );
    }

    render() {
        let rendered = [];
        for (let i = 0; i < 6; i++) {
            rendered.push(this.renderGuess(i));
        }
        return (
            <div>
                <h2>{this.state.helpText}</h2>
                <Button variant="contained" onClick={() => this.onRestart()}>Restart</Button>
                <ResultChooser values={this.state.chooserValues} notify={(i, value) => {
                    const newValues = this.state.chooserValues.fill(value, i, i+1)
                    this.setState({chooserValues: newValues})
                }}/>
                {rendered}
            </div>
        );
    }
}
export {GuessBoard}