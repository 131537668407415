import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const whiteLogos = [
  'logos/csharp.png',
  'logos/js.png',
  'logos/python.jpg',
  'logos/react.png'
];

const logoStyle = {
  width: '100px',
  height: '80px',
  margin: '0 32px',
  opacity: 0.7,
};

export default function LogoCollection() {
  const logos = whiteLogos;

  return (
    <Box id="logos" sx={{ py: 4 }}>
      <Typography
        component="p"
        variant="subtitle2"
        align="center"
        color="text.secondary"
      >
        Experience with multiple frameworks and Platforms
      </Typography>
      <Grid container justifyContent="center" sx={{ mt: 0.5, opacity: 1.0 }}>
        {logos.map((logo, index) => (
          <Grid item key={index}>
            <img
              src={logo}
              alt={`Fake company number ${index + 1}`}
              style={logoStyle}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
