import React, {useState} from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Card from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography';
import getLPTheme from '../../getLPTheme.tsx';
import CssBaseline from '@mui/material/CssBaseline';


export default function SolarAnalysisPage() {
    const LPtheme = createTheme(getLPTheme('dark'));
    const [costPerWatt, setCostPerWatt] = useState(1);
    const [electricityPrice, setElectricityPrice] = useState(0.1);
    const [electricityGrowthRate, setElectricityGrowthRate] = useState(4);
    const [discountRate, setDiscountRate] = useState(7);
    const solarDegradationRate = 0.05
    const [lifetime, setLifetime] = useState(25);
    function getNPV(
        costPerWatt: number,
        electricityPrice: number,
        electricityGrowthRate: number,
        discountRate: number,
        lifetime: number
    ) {
        const cost = costPerWatt * 1000;
        let totalSaved = 0;
        for (let i=1; i <= lifetime; i++) {
            let generatedPerYearkwh = 4 * 365 * ((1 - solarDegradationRate) ** (i - 1));
            let savedInCurrentDollars = generatedPerYearkwh * electricityPrice * (1 + electricityGrowthRate/ 100) ** i;
            let savedInPresentDollars = savedInCurrentDollars * (1 - discountRate/100) ** i;
            totalSaved += savedInPresentDollars;
        }
        return totalSaved - cost;
    }
    function getIRR(
        costPerWatt: number,
        electricityPrice: number,
        electricityGrowthRate: number,
        lifetime: number
    ) {
        let approx_rate = 1.0
        const cost = costPerWatt * 1000;
        
        let error = cost
        while (error > 1) {
            let totalSaved = 0;
            let derivative = 0;
            // newton's method
            for (let i=1; i <= lifetime; i++) {
                let generatedPerYearkwh = 4 * 365 * ((1 - solarDegradationRate) ** (i - 1));
                let savedInCurrentDollars = generatedPerYearkwh * electricityPrice * (1 + electricityGrowthRate/ 100) ** i;
                let savedInPresentDollars = savedInCurrentDollars * approx_rate ** i;
                totalSaved += savedInPresentDollars;

                let derivativeYear = i * savedInCurrentDollars * approx_rate ** (i-1);
                derivative += derivativeYear;
            }
            console.log(approx_rate, totalSaved, derivative);
            approx_rate = approx_rate - ((totalSaved - cost) / derivative);
            error = Math.abs(totalSaved - cost);
        }
        return (1-approx_rate) * 100;
    }
    
    return (
    <ThemeProvider theme={LPtheme}>
    <CssBaseline />
    <Container
    sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: { xs: 14, sm: 20 },
        pb: { xs: 8, sm: 12 },
    }}
    >
    <Stack spacing={6} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
        <Typography
        component="h1"
        variant="h1"
        sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignSelf: 'center',
            textAlign: 'center',
        }}
        >
        Solar Investment Calculator for Indonesia&nbsp;
        </Typography>
        <Typography variant="body1" textAlign="left" color="text.primary">
        Solar power adoption has been very limited in Indonesia, currently accounting for less than a percent of generation as of 2022. In many ways, investing in solar is an uphill battle in Indonesia and is at a disadvantage compared to solar investment in most other countries. However, I am convinced that the sharp fall in the price of PV cells will make investments profitable by 2026, given certain conditions.
        </Typography>
        <Typography variant="body1" textAlign="left" color="text.secondary">
        As a major coal and gas exporter, Indonesia enjoys cheap energy prices, which are further subsidized by the government. Unlike Europe and China, it is not dependent on fossil fuel imports so there is less incentive for the government to subsidize renewables. In addition, although Indonesia lies on the equator, its tropical climate reduces that advantage due to cloud cover. Finally, as an island nation, getting the land for major solar projects will be difficult. These difficulties have historically supressed solar investment, but the price of solar cells have decreased to the point where these challenges may be overcome given the right circumstances. I created this calculator to model the variables and assumptions that must align for solar energy to be profitable.
        </Typography>
        <Typography>
            There are already similar calculators, but none are tailored to Indonesia's situation, and most do not account for the time value of money/discount rate or for changes in electricity prices, which is crucial for making good investment decisions. 
        </Typography>
        <Typography>
        Cost of PV cells and installation ($/W).
        </Typography>
        <Typography color='text.secondary'>
        This is typically measured in dollars/W. For rooftop solar, this is about 2.5-4.0. For utility scale PV plants, 0.4-1.5. 
        </Typography>
        <Slider 
            value={costPerWatt} 
            onChange={(_, val) => setCostPerWatt(val as number)}
            min={0.25}
            max={3.0}
            step={0.25}
            marks={[{value: 0.25, label: "$0.25/W"}, {value: 3, label: "$3.00/W"}]}
            valueLabelDisplay="on"
            />
        <Typography>
        Price of electricity
        </Typography>
        <Typography color='text.secondary'>
            PLN currently sells electricity at $0.10/kWh
        </Typography>
        <Slider 
            value={electricityPrice} 
            onChange={(_, val) => setElectricityPrice(val as number)}
            min={0.04}
            max={0.30}
            step={0.02}
            marks={[{value: 0.04, label: "$0.04/kWh"}, {value: 0.30, label: "$0.30/kWh"}]}
            valueLabelDisplay="on"
            />
        <Typography>
            Projected growth rate of electricity price
        </Typography>
        <Typography color='text.secondary'>
        This variable is the hardest to estimate. If the solar project is an IPP selling to PLN, then it may be possible to negotiate prices in advance, but for a business or homeowner attempting to reduce electricity costs, it really is unknown. Electricity prices will depend on government subsidies, the price of oil, and the rate of decrease in price of renewables. It is actually plausible that electricity prices may decrease if solar panels become cheap enough. For lack of data, I will use a rate of 4%, which is the average inflation rate over the last 10 years.
        </Typography>
        <Slider 
            value={electricityGrowthRate} 
            onChange={(_, val) => setElectricityGrowthRate(val as number)}
            min={-2}
            max={10}
            step={1}
            marks={[{value: -2, label: "-2%"}, {value: 10, label: "10%"}]}
            valueLabelDisplay="on"
            />
        <Typography>
        Interest rate/Discount rate
        </Typography>
        <Typography color='text.secondary'>
            This is the interest rate of the loan used to finance solar investment or the rate of return of an alternate investment.
            Since all calculations are in USD, this interest rate should be compared to USD denominated bonds and investments. My default assumption is 7%
        </Typography>
        <Slider 
            value={discountRate} 
            onChange={(_, val) => setDiscountRate(val as number)}
            min={0}
            max={10}
            step={1}
            marks={[{value: 0, label: "0%"}, {value: 10, label: "10%"}]}
            valueLabelDisplay="on"
            />
        <Typography>
        Solar panel lifetime
        </Typography>
        <Typography color='text.secondary'>
            Solar panels are often sold with 25 year warranty, but can last over 40 years.
        </Typography>
        <Slider 
            value={lifetime} 
            onChange={(_, val) => setLifetime(val as number)}
            min={20}
            max={50}
            step={5}
            marks={[{value: 20, label: "20 years"}, {value: 50, label: "50 years"}]}
            valueLabelDisplay="on"
            />
        <Typography>
        Tax considerations
        </Typography>
        <Typography color='text.secondary'>
        Indonesia has a corporate tax rate of 22%. As companies can amortize investment costs, under sensible tax planning, corporate taxes should not turn a profitable scenario into an unprofitable one, although it would reduce the post-tax earnings. For households, there is no effect.
        </Typography>
        <Typography>
        Solar panel degradation rate
        </Typography>
        <Typography color='text.secondary'>
            0.5%/year
        </Typography>
        Average daily insolation (amount of sunlight accounting for clouds)
        <Typography color='text.secondary'>
            4kW/m2/day
        </Typography>
        <Typography>
        Data from https://ourworldindata.org/energy/country/indonesia#what-sources-does-the-country-get-its-energy-from
        </Typography>
    </Stack>
    <Stack  sx={{width: { sm: '100%', md: '40%' }}}>
        <Card textAlign="center"
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            flexGrow: 1,
            p: 1,
          }}>
            <Typography variant='h3'>
                Results
            </Typography>
            <Typography color="text.secondary">
                Cost per kW capacity: {costPerWatt * 1000}
            </Typography>
            <Typography color="text.secondary">
                Saved in 1st year: {4 * 365 * electricityPrice}
            </Typography>
            <Typography color="text.secondary">
                Saved adjusted for discount rate: {costPerWatt * 1000 + Math.round(getNPV(costPerWatt, electricityPrice, electricityGrowthRate, discountRate, lifetime))}
            </Typography>
            <Typography color="primary.light">
                NPV without tax: {Math.round(getNPV(costPerWatt, electricityPrice, electricityGrowthRate, discountRate, lifetime))}
            </Typography>
            <Typography color="primary.light">
                NPV after 22% tax: {Math.round(getNPV(costPerWatt, electricityPrice, electricityGrowthRate, discountRate, lifetime) * 0.78)}
            </Typography>
            <Typography color="primary.light">
                Internal Rate of Return w/o tax: {getIRR(costPerWatt, electricityPrice, electricityGrowthRate, lifetime).toFixed(2)}%
            </Typography>
            <Typography color="primary.light">
                Internal Rate of Return with tax: {(getIRR(costPerWatt, electricityPrice, electricityGrowthRate, lifetime) * 0.78).toFixed(2)}%
            </Typography>
        </Card>
    </Stack>
    <Stack>
        <Typography>
            From this calculator, we can see that utility scale power plants are viable if they can get the cost of solar cells and installation below $1.5/W. We also see that the growth rate of electricity price is incredibly important
        </Typography>
    </Stack>
    </Container>
    </ThemeProvider>)
}

