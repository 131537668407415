import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';

const userTestimonials = [
  {
    avatar: <Avatar alt="John Milasch" src="/static/images/avatar/2.jpg" />,
    name: 'John Milasch',
    occupation: 'Software Team Lead, Profound Medical',
    testimonial:
      "James professionally communicates his ideas, politely inquires about decisions he may disagree with and has shown to be very well receptive to feedback. He never ceased to surprise with his quick ability to learn topics and implement features in the software. ",
  },
  {
    avatar: <Avatar alt="Bart Gajderowicz, PhD" src="/static/images/avatar/2.jpg" />,
    name: 'Bart Gajderowicz, PhD',
    occupation: 'Executive Director, Urban Data Centre',
    testimonial:
      "James would be a huge asset to any organization looking for a constructive and detail-oriented AI engineer with solid software skills. I am confident that he will excel in any endeavour he chooses and wholeheartedly support his aspirations in the AI domain.",
  }
];



export default function Testimonials() {

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          References
        </Typography>
      </Box>
      <Grid container spacing={4}
          sx={{width: { sm: '100%', md: '80%' }}}
      >
        {userTestimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={6} key={index} 
          sx={{ display: 'flex'}}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
                p: 1,
              }}
            >
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  pr: 2,
                }}
              >
                <CardHeader
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
