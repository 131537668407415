import React from 'react'
import {Board} from './board'
import {GuessBoard} from './guessor-board'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import getLPTheme from '../../getLPTheme.tsx';
import CssBaseline from '@mui/material/CssBaseline';

export default class WordlePage extends React.Component {
    render() {
        const LPtheme = createTheme(getLPTheme('dark'));
        return (
        <ThemeProvider theme={LPtheme}>
        <CssBaseline />
        <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            component="h1"
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
            }}
          >
            Wordle Copy and Solver&nbsp;
          </Typography>
          <Typography variant="body1" textAlign="left" color="text.secondary">
        Soon after I heard of wordle, I wondered whether it has an always winning strategy. 
        I created a solver that uses heuristics based on letter frequencies and showed that it is possible, given
        the limited word list of 2315 words. Wordle does accept more words, 
        but the correct answer will be drawn from that word list. My implementation is not "optimal"
        because it does not definitely minimize the average number of attempts, but it is good enough to never lose.
        In fact, it is possible that a better algorithm can guess all words in 5 guesses, since my algorithm only
        has about 10 words that take 6 guesses. 
          </Typography>
        </Stack>
        <div>
            <h3>My Wordle copy</h3>
            <Board/>
        </div>
        <div>
            <h3>Wordle solver</h3>
            <GuessBoard/>
        </div>
      </Container>
        </ThemeProvider>)
    }
}