import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createHashRouter,
    RouterProvider,
} from "react-router-dom"
import './index.css';
import LandingPage from './LandingPage.tsx';
import WordlePage from './components/wordle/wordle-page.js'
import SolarPage from './components/solar/SolarPage.tsx'
import reportWebVitals from './reportWebVitals';
const router = createHashRouter([
  {
    path: "/",
    element: <LandingPage/>,
  },
  {
      path: "/wordle",
      element: <WordlePage/>
  },
  {
    path: "/solar",
    element: <SolarPage/>
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
